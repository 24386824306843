import { useEffect, useState } from 'react';
import Heading from '../../../../shared/components/heading/heading';
import ImageUpload from '../../../../shared/components/imageUpload/imageUpload';
import Input from '../../../../shared/components/inputFields/input';
import { USER_MANAGEMENT } from '../userManagementConstants';
import style from './addUser.module.scss';
import Button from '../../../../shared/components/button/button';
import buttonStyle from '../../../../shared/components/button/button.module.scss';
import Back from '../../../../shared/components/backButton/backButton';
import { Born, Gender, SexualPreference, Status, Twins, UserType } from '../../../../shared/globalConstants/constants';
import useAxiosInstance from '../../../../hooks/useAxiosInstance';
import { useDispatch } from 'react-redux';
import { addUser, fetchUserListing } from '../../../../redux/AurLaVieAdmin/manageUsers/manageUsers.actions';
import Toaster from '../../../../components/Global/Toaster/Toaster.component';
import { getUserTypes } from '../../../../redux/AurLaVieAdmin/userType/userType.action';

const AddUser = () => {

    const inputFields = [
        {
            type: "text",
            name: "name",
            placeholder: 'Name',
            label: 'Name',
            width: '50%'
        },
        {
            type: "text",
            name: "email",
            placeholder: 'Email ID',
            label: 'Email ID',
            width: '50%'
        },
        {
            type: "password",
            name: "password",
            placeholder: 'Password',
            label: 'Password',
            width: '50%'
        },
        {
            type: "password",
            name: "passwordConfirm",
            placeholder: 'Confirm Password',
            label: 'Confirm Password',
            width: '50%'
        },
        {
            type: "datetime",
            name: "dob",
            placeholder: 'Date of Birth',
            label: 'Date of Birth',
            width: '50%'
        },
        // {
        //     type: "dropdown",
        //     name: "userTypeId",
        //     placeholder: 'User Type',
        //     label: 'User Type',
        //     options: UserType,
        //     width: '50%'
        // },
        {
            type: "radio",
            name: "gender",
            placeholder: 'Gender',
            label: 'Gender',
            options: Gender,
            width: '50%'
        },
        {
            type: "radio",
            name: "twins",
            placeholder: 'Are you twins?',
            label: 'Are you twins?',
            options: Twins,
            width: '50%'
        },
        {
            type: "radio",
            name: "born",
            placeholder: 'Born',
            label: 'Born',
            options: Born,
            width: '50%'
        },
        {
            type: "radio",
            name: "sexualPreference",
            placeholder: 'Sexual Preference',
            label: 'Sexual Preference',
            options: SexualPreference,
            width: '50%'
        },
        {
            type: "radio",
            name: "status",
            placeholder: 'Status',
            label: 'Status',
            options: Status,
            width: '50%'
        },

    ];
    const [formData, setFormData] = useState({});
    const axiosInstance = useAxiosInstance();
    const dispatch = useDispatch();
    const [toastList, setToastList] = useState([]);
    const [appId, setAppId] = useState('');
    const [userTypeId, setUserTypeId] = useState('');

    const handleInputChange = (fieldName, fieldValue) => {
        setFormData({ ...formData, [fieldName]: fieldValue });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const selectedAppId = localStorage.getItem('appId');
        getUserType(axiosInstance, selectedAppId);
        setAppId(selectedAppId);
    }

    const getUserType = (axiosInstance, appId) => {
        dispatch(getUserTypes(axiosInstance, appId)).then((response) => {
            if(response?._id){
                formData['appId'] = appId;
                formData['userTypeId'] = response?._id;
                const params = {
                    isAdmin: true,
                }
                addUserDetails(axiosInstance, formData, params);
            }
        }).catch((error) => {
            setToastList([
                {
                    id: 0,
                    title: "Fetching User Type Failed",
                    description: error?.response?.data?.message,
                    icon: "error",
                },
            ]);
        });
    }

    const addUserDetails = (axiosInstance, userDetails, params) => {
        dispatch(addUser(axiosInstance, userDetails, params)).then((response) => {
            setToastList([
                {
                    id: 0,
                    title: "User Added",
                    description: response.message,
                    icon: "success",
                },
            ]);
        }).catch((error) => {
            console.log('err', error.response.data.message);
            setToastList([
                {
                    id: 0,
                    title: "Error adding user",
                    description: error.response.data.message,
                    icon: "error",
                },
            ]);
        });
    }

    return (
        <div className={style.container}>
            <div className={style.wrapper}>
                <div className={style.back}>
                    <Back title={USER_MANAGEMENT.ADD_USER.BACK.TITLE} description={USER_MANAGEMENT.ADD_USER.BACK.DESCRIPTION} />
                </div>
                <div className={style.heading}>
                    <Heading title={USER_MANAGEMENT.ADD_USER.HEADING.TITLE} description={USER_MANAGEMENT.ADD_USER.HEADING.DESCRIPTION} />
                    <div className={style.app_user}>
                        <img src='/icons/info_blue.svg' alt='info' width={30} />
                        <span className={style.app}>Aur La Vie</span>
                        <span className={style.user}>Normal User</span>
                    </div>
                </div>
                <div className={style.form_container}>
                    <div className={style.upload}>
                        <ImageUpload title={USER_MANAGEMENT.ADD_USER.UPLOAD.TITLE} />
                    </div>
                    <form onSubmit={handleSubmit}>
                        {inputFields.map((inputField, index) => (
                            <div key={index} className={style.input_field} style={{ width: inputField.width }}>
                                <Input
                                    type={inputField.type}
                                    label={inputField.label}
                                    value={formData[inputField.name]}
                                    placeholder={inputField.placeholder}
                                    onChange={(value) => handleInputChange(inputField.name, value)}
                                    options={inputField.options}
                                />
                            </div>
                        ))}
                        <div className={style.action_button}>
                            <Button text={USER_MANAGEMENT.ADD_USER.BUTTONS.TITLE} buttonType={`${buttonStyle.button_primary}`} />
                        </div>
                    </form>
                </div>
            </div>
            <Toaster
                toastList={toastList}
                position={"top-right"}
                autoDelete={true}
                autoDeleteTime={2500}
            />
        </div>
    )
}

export default AddUser;