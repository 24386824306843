import { useEffect, useState } from "react";
import Heading from "../../../shared/components/heading/heading";
import Table from "../../../shared/components/table/table";
import { USER_MANAGEMENT } from "./userManagementConstants";
import './userManagement.scss';
import Input from "../../../shared/components/inputFields/input";
import { AppType } from "../../../shared/globalConstants/constants";
import Button from "../../../shared/components/button/button";
import CenterModal from "../../../shared/components/modal/centeredModal/centeredModal";
import SelectAppUserType from "./selectAppUserType/selectAppUserType";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserListing } from "../../../redux/AurLaVieAdmin/manageUsers/manageUsers.actions";
import { selectUsersListingData } from "../../../redux/AurLaVieAdmin/manageUsers/manageUsers.selectors";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import moment from "moment";
import Toaster from "../../../components/Global/Toaster/Toaster.component";

const UserManagment = () => {

    const [users, setUsers] = useState([{
        id: '1',
        userId: '1',
        firstName: 'Joan',
        lastName: 'M Sims',
        emailId: 'joan@mail.com',
        app: 'Feng shui',
        createdDate: '12 / 04 / 2023',
        userType: 'Normal'
    }]);
    const [selectedApplicationType, setSelectedApplicationType] = useState();
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const allUsersdaa = useSelector(selectUsersListingData);
    const axiosInstance = useAxiosInstance();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [toastList, setToastList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [isModalClosed, setIsModalClosed] = useState(false);

    useEffect(() => {
        setPage(1);
        const params = {
            page: 1,
            limit: pageSize,
            isAdmin: true,
        }
        getUsers(axiosInstance, params);
    }, [axiosInstance, pageSize]);

    useEffect(() => {
        let timerId;
        const fetchResults = async () => {
            try {
                const params = {
                    page: 1,
                    limit: pageSize,
                    isAdmin: true,
                    keyword: searchTerm
                }
                getUsers(axiosInstance, params);
            } catch (error) {
                console.log(error);
            }
        };

        if (searchTerm) {
            timerId = setTimeout(() => {
                fetchResults();
            }, 500);
        }
        return () => {
            clearTimeout(timerId);
        };
    }, [searchTerm]);

    const getUsers = (axiosInstance, params) => {
        dispatch(fetchUserListing(axiosInstance, params)).then((response) => {
            const updatedUsers = response.data.data.listingData.map(user => {
                return {
                    id: user?._id,
                    // userId: '19',
                    name: user?.name,
                    // lastName: 'M Sims',
                    emailId: user?.email,
                    // app: 'Aur la Vie',
                    createdDate: moment(user?.created_at).format("MM/DD/YYYY"),
                    userType: user?.userTypeId?.typeName
                }
            })
            setUsers(updatedUsers);
        }).catch((err) => {
            setToastList([
                {
                    id: 0,
                    title: "Fething Users Failed",
                    description: err?.response?.data?.message,
                    icon: "error",
                },
            ]);
        });
    }

    const onApplicationChange = (event) => {
        console.log('event', event, users)
    }

    const onSearch = (event) => {
        setSearchTerm(event);
    }

    const onEdit = () => {
        navigate('/editUser');
    }

    const onDelete = () => {

    }

    const onView = (event) => {
        navigate(`/viewUser`, {state:{email: event.emailId}});
    }

    const addUser = () => {
        setShowAddUserModal(true);
        setIsModalClosed(false);
    }

    const deleteUser = () => {

    }

    const closeModal = () => {
        setShowAddUserModal(false);
        setIsModalClosed(true);
    }

    return <section className="container">
        <div className="wrapper">
            <CenterModal isOpen={showAddUserModal} onClose={closeModal}>
                <SelectAppUserType isModalClosed={isModalClosed}/>
            </CenterModal>
            <section className="title_filters">
                <Heading title={USER_MANAGEMENT.HEADING.TITLE} description={USER_MANAGEMENT.HEADING.DESCRIPTION} icon='/icons/user_settings.png' />
                <div className="filters">
                    <Input type={'dropdown'} placeholder={USER_MANAGEMENT.FILTERS.APP_TYPE.PLACEHOLDER} value={selectedApplicationType} options={AppType} width={'160px'} onChange={onApplicationChange} />
                    <Button icon={'/icons/add.svg'} text={'Add User'} onClick={addUser} />
                    <Input type={'search'} placeholder={USER_MANAGEMENT.FILTERS.SEARCH.PLACEHOLDER} value={selectedApplicationType} options={AppType} onChange={onSearch} />
                    <Button icon={'/icons/delete.svg'} onClick={deleteUser} />
                </div>
            </section>
            <section className="listing">
                <Table data={users} onView={(event) => onView(event)} onEdit={onEdit} onDelete={onDelete} />
            </section>
        </div>
        <Toaster
            toastList={toastList}
            position={"top-right"}
            autoDelete={true}
            autoDeleteTime={2500}
        />
    </section>
}

export default UserManagment;