import React, { useState } from 'react';
import style from './imageUpload.module.scss';

const ImageUpload = (props) => {

    const { title } = props;
  const [selectedImage, setSelectedImage] = useState(null);
  const [dragging, setDragging] = useState(false);

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    // You can add validation here to check for valid image file types, size, etc.
    setSelectedImage(imageFile);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);

    const imageFile = event.dataTransfer.files[0];
    // You can add validation here to check for valid image file types, size, etc.
    setSelectedImage(imageFile);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  };

  return (
    <div className={style.container}>
      <h3 className={style.title}>{title}</h3>
      <div
        className={style.upload_container}
        style={{border: dragging ? '2px solid #0E4D92' : '2px solid #DEDEDE'}}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: 'none' }}
          id='upload'
        />
        <label htmlFor='upload' className={style.label}></label>
        {selectedImage && (
          <div className={style.image_preview}>
            {/* <h4>Selected Image:</h4> */}
            <img
              src={URL.createObjectURL(selectedImage)}
              alt="Selected"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
