import { Route, Routes } from "react-router-dom";
import { routeItems } from "./routing/routes";
import React, { Suspense } from "react";
import LoginPage from "./pages/Login/Login.page";
import HomePage from "./pages/Dashboard/home.page";
import PrivateRoute from "./routing/privateRoute";
import ForgetPasswordPage from "./pages/ForgetPassword/forgetPassword.page";
import ResetPasswordPage from "./pages/resetPassword/resetPassword.page";
import UserManagment from "./pages/admin/userManagement/userManagement";
import HeaderComponent from "./components/Global/header/header.component";
import AddUser from "./pages/admin/userManagement/addUser/addUser";
import ViewUser from "./pages/admin/userManagement/viewUser/viewUser";
import EditUser from "./pages/admin/userManagement/editUser/editUser";
import RedirectAppPage from "./pages/redirectApp/redirectApp.page";

const PageList = {
  LoginPage: <LoginPage />,
  HomePage: <HomePage />,
  ForgetPassword: <ForgetPasswordPage />,
  ResetPassword: <ResetPasswordPage />,
  UserManagement: <UserManagment />,
  AddUser: <AddUser />,
  ViewUser: <ViewUser />,
  EditUser: <EditUser />,
  RedirectApp: <RedirectAppPage />,
};

const App = () => {
  return (
    <Suspense>
      <Routes>
        {routeItems.map((item, i) => {
          if (!item.protected) {
            return (
              <Route
                key={item.component}
                path={item.route}
                element={PageList[item.component]}
              />
            );
          } else {
            return (
              <Route
                key={item.component}
                path={item.route}
                element={
                  <PrivateRoute
                    path={item.route}
                    children={PageList[item.component]}
                  />
                }
              />
            );
          }
        })}
      </Routes>
    </Suspense>
  );
};

export default App;
