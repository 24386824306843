import React, { useEffect, useState } from 'react';
import './table.scss';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const Table = ({ data, onEdit, onDelete, onView }) => {

    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [activeRow, setActiveRow] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [checkedRows, setCheckedRows] = useState([1]);
    const itemsPerPage = 10;
    const totalPages = Math.ceil(data?.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const [currentData, setCurrentData] = useState(() =>
        data?.slice(0, itemsPerPage)
    );

    useEffect(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setCurrentData(data?.slice(startIndex, endIndex));
    }, [currentPage, data, itemsPerPage]);

    useEffect(() => {
        setCurrentPage(1);
        setCurrentData(data?.slice(0, itemsPerPage));
    }, [data, itemsPerPage]);

    useEffect(() => {
        setCheckedRows([activeRow]);
    }, [activeRow]);

    const handleSelectAll = (event) => {
        const isChecked = event.target.checked;
        setSelectAll(isChecked);

        if (isChecked) {
            setSelectedRows(data);
        } else {
            setSelectedRows([]);
        }
    };

    const handleCheckboxChange = (event, index) => {
        const checked = event.target.checked;
        if (checked) {
            setCheckedRows([...checkedRows, index]); // Add checked row to checkedRows state
        } else {
            setCheckedRows(checkedRows.filter(row => row !== index)); // Remove unchecked row from checkedRows state
        }
    };

    const handleRowSelect = (event, item) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setSelectedRows([...selectedRows, item]);
        } else {
            setSelectedRows(selectedRows.filter(row => row.id !== item.id));
        }
    };

    // Function to handle previous page click
    const handlePrevious = () => {
        setCurrentPage(currentPage - 1);
    };

    // Function to handle next page click
    const handleNext = () => {
        setCurrentPage(currentPage + 1);
    };

    // Function to handle page number click
    const handlePageNumber = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Function to handle row click
    const handleRowClick = (index) => {
        setActiveRow(index);
    };

    const capitalize = (str) => {
        const words = str.split(/(?=[A-Z])/);
        return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };

    const renderColumns = (item) => {
        return Object.keys(item).map(key => (
            (key !== 'id' ? <td key={key}>{item[key]}</td> : null)
        ));
    };

    return (
        <div className="table_container">
            <table>
                <thead>
                    <tr>
                        <th>
                            {/* <input
                                type="checkbox"
                                checked={checkedRows.length === currentData.length} 
                                onChange={handleSelectAll}
                            /> */}
                            All
                        </th>
                        {Object.keys(data && data[0]).map(key => (
                            (key !== 'id' ? <th key={key}>{capitalize(key)}</th> : null)
                        ))}
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentData.map((row, index) => (
                        <tr key={index} className={activeRow === index ? 'active' : ''} onClick={() => handleRowClick(index)}>
                            <td>
                                <label className="checkbox_container">
                                    <input type="checkbox" checked={checkedRows.includes(row.id)}
                                        onChange={(event) => handleCheckboxChange(event, row.id)} />
                                    <span className="checkmark"></span>
                                </label>
                            </td>
                            {renderColumns(row)}
                            <td>
                                <button className='action_button' onClick={() => onView(row)}>
                                    <img src='/icons/view.svg' alt="view" width={25} />
                                </button>
                                <button className='action_button' onClick={() => onEdit(row)}>
                                    <img src='/icons/edit.svg' alt="edit" width={20} height={20} />
                                </button>
                                <button className='action_button' onClick={() => onDelete(row)}>
                                    <img src='/icons/delete.svg' alt="delete" width={20} height={20} />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination">
                <button className='previous' onClick={handlePrevious} disabled={currentPage === 1}>
                    <FaChevronLeft />
                </button>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
                    <button
                        key={pageNumber}
                        onClick={() => handlePageNumber(pageNumber)}
                        className={currentPage === pageNumber ? 'active' : ''}
                    >
                        {pageNumber}
                    </button>
                ))}
                <button className='next' onClick={handleNext} disabled={currentPage === totalPages}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
};

export default Table;
