export const endpoints = {
  // adminUser ENDPOINT
  adminUser: "/admin",
  adminUserLogin: "/adminUser/login",
  adminUserForgetPassword: "/adminUser/forget-password",
  adminUserResetPassword: "/adminUser/reset-password",

  //user

  userListing: "/user/getUsers",
  addUser: "/user/adduser",
  viewUser: "/user/getuserdetails", 

  /**
   * Get Apps Url
   */
  getApps: "/apps",

  /**
   * Get User Types
   */
  getUserTypes: "/userTypes",
};
