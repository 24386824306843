import React, { useState } from 'react';
import style from './input.module.scss';
import { FaChevronDown, FaSearch } from "react-icons/fa";
import useClickOutside from '../../../hooks/useClickOutside';

const Input = ({ type, name, label, placeholder, required, value, width, onChange, options }) => {

  const [isOpen, setIsOpen] = useState(false);
  const { ref, isVisible, setIsVisible } = useClickOutside(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsVisible(true);
  };

  const selectOption = (option) => {
    onChange(option);
    setIsOpen(false);
    setIsVisible(false);
  };

  const renderInput = () => {
    switch (type) {
      case 'text':
        return (
          <div className={`${style.input_field}`}>
            <input
              type="text"
              name={name}
              id={label}
              placeholder={placeholder}
              required={required}
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          </div>
        );
      case 'number':
        return (
          <div className={`${style.input_field}`}>
            <input
              type="number"
              id={label}
              name={name}
              required={required}
              placeholder={placeholder}
              value={value}
              onChange={(e) => onChange(parseFloat(e.target.value))}
            />
          </div>
        );
      case 'email':
        return (
          <div className={`${style.input_field}`}>
            <input
              type="email"
              id={label}
              name={name}
              required={required}
              placeholder={placeholder}
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          </div>
        );
      case 'password':
        return (
          <div className={`${style.input_field}`}>
            <input
              type="password"
              id={label}
              name={name}
              required={required}
              placeholder={placeholder}
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          </div>
        );
      case 'search':
        return (
          <div className={`${style.input_field}`}>
            <input
              type="search"
              id={label}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
            <FaSearch />
          </div>
        );
      case 'dropdown':
        return (
          <div className={`${style.input_field}`} onClick={toggleDropdown} style={{ width: width }}>
            <div className={`${style.dropdown_header}`}>
              {value?.label !== undefined ? value?.label : placeholder} <FaChevronDown />
            </div>
            {(isOpen && isVisible) && (
              <ul ref={ref} className={`${style.dropdown_list}`}>
                {options.map((option) => (
                  <li key={option.value} onClick={() => selectOption(option)}>
                    {option.label}
                  </li>
                ))}
              </ul>
            )}
          </div>
        );
      case 'radio':
        return (
          <div className={style.radio_field}>
            {options.map((option) => (
              <label className={style.radio_container} key={option.value}>
                <input
                  type="radio"
                  name={name}
                  id={option.label}
                  value={option.value}
                  checked={value === option.value}
                  onChange={() => onChange(option.value)}
                />
                <span className={style.checkmark}></span>
                {option.label}
              </label>
            ))}
          </div>
        );
      case 'checkbox':
        return (
          <div>
            {options.map((option) => (
              <label key={option.value}>
                <input
                  type="checkbox"
                  id={option.label}
                  name={name}
                  value={option.value}
                  checked={value.includes(option.value)}
                  onChange={() => {
                    const updatedValue = [...value];
                    if (value.includes(option.value)) {
                      updatedValue.splice(value.indexOf(option.value), 1);
                    } else {
                      updatedValue.push(option.value);
                    }
                    onChange(updatedValue);
                  }}
                />
                {option.label}
              </label>
            ))}
          </div>
        );
      case 'date':
        return (
          <div className={`${style.input_field}`}>
            <input
              type="date"
              name={name}
              id={label}
              required={required}
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          </div>
        );
      case 'time':
        return (
          <div className={`${style.input_field}`}>
            <input
              type="time"
              name={name}
              id={label}
              value={value}
              required={required}
              onChange={(e) => onChange(e.target.value)}
            />
          </div>
        );
      case 'datetime':
        return (
          <div className={`${style.input_field}`}>
            <input
              type="datetime-local"
              id={label}
              name={name}
              value={value}
              required={required}
              onChange={(e) => onChange(e.target.value)}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={`${style.input_fields_container}`}>
      {label !== undefined ? <label htmlFor={label}>{label}</label> : null}
      {renderInput()}
    </div>
  );
};

export default Input;
