import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ProgressLoaderComponent from "../../components/Global/ProgressLoader/ProgressLoader.component";

const RedirectAppPage = () => {
  const location = useLocation();

  useEffect(() => {
    handleRedirection();
  }, []);

  const handleRedirection = () => {
    const routeSplit = location?.pathname.split("/");
    const urlToken = routeSplit[routeSplit.length - 1];

    let ua = navigator.userAgent.toLowerCase();
    let isAndroid = ua.indexOf("android") > -1; // android check
    let isIphone = ua.indexOf("iphone") > -1; // ios check

    if (isIphone == true) {
      let app = {
        launchApp: function () {
          window.location.href = `com.aurlavie://forgotpassword/${urlToken}`; //which page to open(now from mobile, check its authorization)
          setTimeout(this.openWebApp, 500);
        },
        openWebApp: function () {
          window.location.href =
            "https://apps.apple.com/us/app/aur-la-vie/id1456340470";
        },
      };
      app.launchApp();
    } else if (isAndroid == true) {
      let app = {
        launchApp: function () {
          window.location.href = `https://com.aurlavie/${urlToken}`; //which page to open(now from mobile, check its authorization)
          setTimeout(this.openWebApp, 500);
        },
        openWebApp: function () {
          window.location.href =
            "https://play.google.com/store/apps/details?id=com.fashioncolor.fashioncolorapp";
        },
      };
      app.launchApp();
    } else {
      let app = {
        launchApp: function () {
          setTimeout(
            (window.location.href =
              "https://play.google.com/store/apps/details?id=com.fashioncolor.fashioncolorapp"),
            500
          );
        },
      };
      app.launchApp();

      //navigate to website url
    }
  };

  return <ProgressLoaderComponent />;
};
export default RedirectAppPage;
