import React, { useEffect, useState } from "react";
import "./sidebar.component.scss";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { IoIosNotifications, IoIosPeople } from "react-icons/io";
import { NavLink, useLocation } from "react-router-dom";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { useDispatch } from "react-redux";
//import { fetchCheckSubscription } from '../../../redux/AurLaVieAdmin/manageSubscription/manageSubscription.actions';

const SidebarComponent = () => {
  const location = useLocation();
  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const [subscribed, setSubscribed] = useState(false);
  const [userType, setUserType] = useState("");
  const [sideBarList, setSideBarList] = useState([]);

  const commonSideBarList = [
    // {
    //   name: "My Account",
    //   route: "/myAccount",
    //   icon: <MdOutlineSpaceDashboard />,
    // },
    // {
    //   name: "Manage Notifications",
    //   route: "/manageNotifications",
    //   icon: <IoIosNotifications />,
    // },
    {
      name: "User Management",
      route: "/userManagement",
      icon: <MdOutlineSpaceDashboard />,
    },
  ];

  // useEffect(()=>{
  //   const type = localStorage.getItem("userType")
  //   setUserType(type)
  //   if(location?.pathname==="/subscriptionPlans"){
  //     dispatch(fetchCheckSubscription(axiosInstance, {})).then(res=>{
  //       if(res.data.data.result.length>0){
  //         setSubscribed(true)
  //       }else{
  //         setSubscribed(false)
  //       }
  //     })
  //   }
  // },[])

  useEffect(() => {
    let newSidebarValues = [];
    switch (userType?.toLowerCase()) {
      case "aptillio-admin":
        newSidebarValues = [];
        break;

        // case "org-admin":
        //   newSidebarValues = [
        //     {
        //       name: "Dashboard",
        //       route: "/manageHiringManager",
        //       icon: <IoIosPeople />,
        //     },
        //     {
        //       name: "Manage Job Roles",
        //       route: "/manageJobRole",
        //       icon: <IoIosNotifications />,
        //     },
        //     {
        //       name: "Manage Invoices",
        //       route: "/manageInvoices",
        //       icon: <IoIosPeople />,
        //     },
        //     {
        //       name: "Subscriptions",
        //       route: "/subscriptionPlans",
        //       icon: <MdOutlineSpaceDashboard />,
        //     },
        //   ];
        //   break;
        // case "hiring-manager":
        //   newSidebarValues = [
        //     {
        //       name: "Dashboard",
        //       route: "/manageCandidate",
        //       icon: <IoIosPeople />,
        //     },
        //     {
        //       name: "Manage Job Roles",
        //       route: "/manageJobRole",
        //       icon: <IoIosNotifications />,
        //     },
        //   ];
        //   break;
        // case "business-psychologist":
        //   newSidebarValues = [
        //     {
        //       name: "Dashboard",
        //       route: "/manageCandidate",
        //       icon: <IoIosPeople />,
        //     },
        //     {
        //       name: "Manage Calendar",
        //       route: "/manageCalender",
        //       icon: <IoIosNotifications />,
        //     },
        //     {
        //       name: "Resume Bank",
        //       route: "/manageResume",
        //       icon: <MdOutlineSpaceDashboard />,
        //     },
        //   ];
        break;
      default:
        break;
    }
    commonSideBarList.unshift(...newSidebarValues);
    setSideBarList(commonSideBarList);
  }, [userType]);

  return (
    <div className="sidebar_container">
      <div className="sidebar_wrapper">
        <div className="sidebar_main">
          {sideBarList?.map((sidebar, i) => {
            if (
              location?.pathname === "/subscriptionPlans" &&
              userType === "org-admin" &&
              !subscribed
            ) {
              return (
                <NavLink
                  onClick={(e) => e.preventDefault()}
                  end={sidebar.route === "/"}
                  key={i}
                  to={sidebar.route}
                  exact={"/"}
                  activeclassname="active"
                  className="sidebar_main-dashboard disabled"
                >
                  {sidebar.icon}
                  <p>{sidebar.name}</p>
                </NavLink>
              );
            }

            return (
              <NavLink
                end={sidebar.route === "/"}
                key={i}
                to={sidebar.route}
                exact={"/"}
                activeclassname="active"
                className="sidebar_main-dashboard"
              >
                {sidebar.icon}
                <p>{sidebar.name}</p>
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SidebarComponent;
