import React, { useEffect, useState } from 'react';
import loader from '../../../assets/gif/loader.gif';
import './ProgressLoader.component.scss';

const ProgressLoaderComponent = () => {

    return (
        <div className="progressloader-container">
            <div className="progressloader-main">
                <img src={loader} alt="aptillio-loader"/>
            </div>
        </div>
    );
};

export default ProgressLoaderComponent;