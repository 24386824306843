import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import ManageUsersComponent from "../../components/Aur-La-Vie-Admin/manageUsers/ManageUsers.component";
import HomeComponent from "../../components/Global/HomeLayout/HomeLayout.component";
import UserManagment from "../admin/userManagement/userManagement.jsx";
import './home.styles.scss';

const Homepage = () => {
  const navigateTo = useNavigate();
  const checkAuth = () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("accessToken");

    if (!userId || !token) {
      navigateTo("/login");
    }
  };
  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <div className="homepage_container">
      <div className="homepage_wrapper">
        <HomeComponent childrenComponent={<UserManagment/>} />
      </div>
    </div>
  );
};

export default Homepage;
