import AppSwitchButton from "../../../../shared/components/appSwitchButton/appSwitchButton";
import Back from "../../../../shared/components/backButton/backButton"
import Button from "../../../../shared/components/button/button";
import Heading from "../../../../shared/components/heading/heading";
import { USER_MANAGEMENT } from "../userManagementConstants";
import style from './viewUser.module.scss';
import { useEffect, useState } from "react";
import { viewUser } from "../../../../redux/AurLaVieAdmin/manageUsers/manageUsers.actions";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";

const ViewUser = () => {

    const location = useLocation();
    const [toastList, setToastList] = useState([]);
    const axiosInstance = useAxiosInstance();
    const dispatch = useDispatch();
    const [userDetails, setUserDetails] = useState({});

    useEffect(() => {
        const queryParams = {
            isAdmin: true
        }
        viewUserDetails(axiosInstance, location.state.email, queryParams);
    }, []);

    const deleteUser = () => {

    }

    const viewUserDetails = (axiosInstance, userId, params) => {
        dispatch(viewUser(axiosInstance, userId, params)).then((response) => {
            console.log('view', response[0])
            setUserDetails(response[0]);
        }).catch((error) => {
            console.log('err', error.response.data.message);
            setToastList([
                {
                    id: 0,
                    title: "Error adding user",
                    description: error.response.data.message,
                    icon: "error",
                },
            ]);
        });
    }

    return (
        <div className={style.container}>
            <div className={style.wrapper}>
                <div className={style.back}>
                    <Back title={USER_MANAGEMENT.VIEW_USER.BACK.TITLE} description={USER_MANAGEMENT.VIEW_USER.BACK.DESCRIPTION} />
                </div>
                <div className={style.heading}>
                    <Heading title={userDetails?._id?.slice(0, 5)} description={USER_MANAGEMENT.VIEW_USER.HEADING.DESCRIPTION} />
                    <div className={style.action_buttons}>
                        <AppSwitchButton/>
                        <Button icon={'/icons/edit.svg'} onClick={deleteUser} />
                        <Button icon={'/icons/delete.svg'} onClick={deleteUser} />
                    </div>
                </div>
                <div className={style.details}>
                    <div className={style.profile_image}>
                        <img src="/icons/user_blue.svg" alt="profile" />
                    </div>
                    <div className={style.profile_details}>
                        <Heading title={userDetails?._id?.slice(0, 5)} description={USER_MANAGEMENT.VIEW_USER.HEADING.DESCRIPTION} fontSize={'1.4rem'} />
                        <hr className={style.divider} />
                        <div className={style.column}>
                            <div className={style.row}>
                                <div className={style.sub_detail}>
                                    <label className={style.label}>User Id</label>
                                    <p className={style.value}>{userDetails?._id?.slice(0, 5)}</p>
                                </div>
                                <div className={style.sub_detail}>
                                    <label className={style.label}>Name</label>
                                    <p className={style.value}>{userDetails?.name}</p>
                                </div>
                                <div className={style.sub_detail}>
                                    <label className={style.label}>Email Id</label>
                                    <p className={style.value}>{userDetails?.email}</p>
                                </div>
                            {/* </div> */}
                            {/* <div className={style.row}> */}
                                <div className={style.sub_detail}>
                                    <label className={style.label}>Using App</label>
                                    <p className={style.value}>Aur La Vie</p>
                                </div>
                                {/* <div className={style.sub_detail}>
                                    <label className={style.label}>Password</label>
                                    <p className={style.value}>******************</p>
                                </div> */}
                            {/* </div>
                            <div className={style.row}> */}
                                <div className={style.sub_detail}>
                                    <label className={style.label}>Created On</label>
                                    <p className={style.value}>{moment(userDetails?.created_at).format("MM/DD/YYYY")}</p>
                                </div>
                                <div className={style.sub_detail}>
                                    <label className={style.label}>Active User</label>
                                    <p className={style.value}>{userDetails?.status}</p>
                                </div>
                                <div className={style.sub_detail}>
                                    <label className={style.label}>Premium User</label>
                                    <p className={style.value}>{userDetails?.isVip ? 'Yes' : 'No'}</p>
                                </div>
                                <div className={style.sub_detail}>
                                    <label className={style.label}>VIP User</label>
                                    <p className={style.value}>{userDetails?.isVip ? 'Yes' : 'No'}</p>
                                </div>
                            </div>
                        </div>
                        <div className={style.astrology_details}>
                            <Heading title={'Astrology Profile Details'} description={USER_MANAGEMENT.VIEW_USER.HEADING.DESCRIPTION} fontSize={'1.4rem'} />
                            <hr className={style.divider} />
                            <h4 className={style.sub_heading}>Astrology Profile 1</h4>
                            <div className={style.astrologers}>
                                <div className={style.profile_image}>
                                    <img src="/icons/user_blue.svg" alt="profile" />
                                </div>
                                <div className={style.column}>
                                    <div className={style.row}>
                                        <div className={style.sub_detail}>
                                            <label className={style.label}>Full Name</label>
                                            <p className={style.value}>Nya Schneider</p>
                                        </div>
                                        <div className={style.sub_detail}>
                                            <label className={style.label}>Date Of Birth</label>
                                            <p className={style.value}>12 / 10 / 2022</p>
                                        </div>
                                    </div>
                                    <div className={style.row}>
                                        <div className={style.sub_detail}>
                                            <label className={style.label}>Birth Time</label>
                                            <p className={style.value}>10:30 PM</p>
                                        </div>
                                        <div className={style.sub_detail}>
                                            <label className={style.label}>Are You Twin</label>
                                            <p className={style.value}>No</p>
                                        </div>
                                    </div>
                                    <div className={style.row}>
                                        <div className={style.sub_detail}>
                                            <label className={style.label}>Gender At Birth</label>
                                            <p className={style.value}>Female</p>
                                        </div>
                                        <div className={style.sub_detail}>
                                            <label className={style.label}>Sexual Preference</label>
                                            <p className={style.value}>Straight</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className={style.divider} />
                            <h4 className={style.sub_heading}>Astrology Profile 2</h4>
                            <div className={style.astrologers}>
                                <div className={style.profile_image}>
                                    <img src="/icons/user_blue.svg" alt="profile" />
                                </div>
                                <div className={style.column}>
                                    <div className={style.row}>
                                        <div className={style.sub_detail}>
                                            <label className={style.label}>Full Name</label>
                                            <p className={style.value}>Nya Schneider</p>
                                        </div>
                                        <div className={style.sub_detail}>
                                            <label className={style.label}>Date Of Birth</label>
                                            <p className={style.value}>12 / 10 / 2022</p>
                                        </div>
                                    </div>
                                    <div className={style.row}>
                                        <div className={style.sub_detail}>
                                            <label className={style.label}>Birth Time</label>
                                            <p className={style.value}>10:30 PM</p>
                                        </div>
                                        <div className={style.sub_detail}>
                                            <label className={style.label}>Are You Twin</label>
                                            <p className={style.value}>No</p>
                                        </div>
                                    </div>
                                    <div className={style.row}>
                                        <div className={style.sub_detail}>
                                            <label className={style.label}>Gender At Birth</label>
                                            <p className={style.value}>Female</p>
                                        </div>
                                        <div className={style.sub_detail}>
                                            <label className={style.label}>Sexual Preference</label>
                                            <p className={style.value}>Straight</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewUser;