import { createContext, useCallback, useEffect, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});

  useEffect(() => {
    setAuth({
      refreshToken: localStorage.getItem("refreshToken"),
      accessToken: localStorage.getItem("accessToken"),
      userId: localStorage.getItem("userId"),
      keepMeloggedIn: localStorage.getItem("keepMeLoggedIn"),
    });
  }, []);

  const getUserAuth = useCallback(() => {
    return {
      accessToken: localStorage.getItem("accessToken"),
      refreshToken: localStorage.getItem("refreshToken"),
      userId: localStorage.getItem("userId"),
      keepMeloggedIn: localStorage.getItem("keepMeLoggedIn"),
    };
  }, []);

  const removeUserAuth = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userId");
    localStorage.setItem("keepMeLoggedIn", false);
    setAuth({});
  };

  const createUserAuth = (accessToken, refreshToken, userId) => {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("userId", userId);
  };

  return (
    <AuthContext.Provider
      value={{ auth, createUserAuth, removeUserAuth, getUserAuth }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
// export const useAuth = () => useContext(AuthContext);
