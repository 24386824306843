import { endpoints } from "../../../constants/url.endpoint";
import { getAppsAction } from "./getApps.reducer";

const {
    getAppsSuccess,
    resetGetAppsData
} = getAppsAction;

export const getApps =
    (axiosInstance, appName) => async (dispatch) => {
        return new Promise((resolve, reject) => {
            axiosInstance({
                url: endpoints.getApps,
                method: "GET",
                params: appName,
            })
                .then((response) => {
                    dispatch(getAppsSuccess(response?.data?.data));
                    resolve(response?.data?.data);
                })
                .catch((error) => {
                    console.log('er', error)
                    dispatch(resetGetAppsData());
                    reject(error);
                });
        });
    };