import { useState } from 'react';
import { APP } from '../../globalConstants/constants';
import style from './appSwitchButton.module.scss';

const AppSwitchButton = () => {

    const [isActive, setIsActive] = useState(false);

    const handleClick = (app) => {
        setIsActive(app);
    }

    return (
        <div className={style.container}>
            <button className={isActive === APP.FENG_SHUI ? `${style.active}` : `${style.inactive}`} onClick={() => handleClick(APP.FENG_SHUI)}>
                <img src={isActive === APP.FENG_SHUI ? '/icons/fs_grey.svg' : '/icons/fs_grey.svg'} alt='Feng Shui' />
                <span>{APP.FENG_SHUI}</span>
            </button>
            <button className={isActive === APP.AUR_LA_VIE ? `${style.active}` : `${style.inactive}`} onClick={() => handleClick(APP.AUR_LA_VIE)}>
                <img src={isActive === APP.AUR_LA_VIE ? '/icons/alv_grey.svg' : '/icons/alv_grey.svg'} alt='Feng Shui' />
                <span>{APP.AUR_LA_VIE}</span>
            </button>
        </div>
    )
}

export default AppSwitchButton;