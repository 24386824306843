import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import InputEvent from "../../components/Global/inputs/input.component";
import { forgetPasswordAction } from "../../redux/users/user.actions";
import "./forgetPassword.styles.scss";
import { validate } from "./forgetPasswordValidation";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "./forgetPassword.styles.scss";
import { AiOutlineClose } from "react-icons/ai";
import { forgetPasswordMessage } from "./forgetPassword.constant";

export default function ForgetDialogue({
  openAlert,
  alertMessage,
  setConfirmDiaogue,
  setConfirmAction,
}) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(openAlert);
  }, [openAlert]);

  React.useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, []);

  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [isForgetPasswordDone, setIsForgetPasswordDone] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState(null);

  const handleForgetPassword = () => {
    dispatch(forgetPasswordAction({ email }))
      .then((res) => {
        setIsForgetPasswordDone(true);
      })
      .catch((err) => {
        setApiErrorMessage(err?.response?.data?.message || err?.message);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setApiErrorMessage(null);
    setFormErrors(validate({ email: email }));
    setIsSubmit(true);
    setConfirmAction(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      handleForgetPassword();
    }
  }, [formErrors]);

  const handleClose = () => {
    setEmail("");
    setIsForgetPasswordDone(false);
    setConfirmDiaogue({ openAlert: false, alertMessage: "" });
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: isForgetPasswordDone ? "25%" : "38%",
            // height: isForgetPasswordDone
            //   ? "20%"
            //   : apiErrorMessage
            //   ? "56%"
            //   : "52%",
            overflowY: "unset",
            maxWidth: "500px",
            minWidth: '350px',
            borderRadius: '10px'
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          fontWeight: "900",
        }}
        className="dialogue-title"
        id="alert-dialog-title"
      >
        {alertMessage}
      </DialogTitle>
      {isForgetPasswordDone ? (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p className="dialogue-description">{forgetPasswordMessage}</p>
          </DialogContentText>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p className="dialogue-description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
              efficitur arcu vel sapien posuere accumsan.
            </p>
            <InputEvent
              text="Enter Registered Email Id"
              value={email}
              setValue={setEmail}
            />
            {formErrors?.email && (
              <>
                <p style={{ color: "red" }}>{formErrors?.email}</p>
              </>
            )}
            {apiErrorMessage && (
              <>
                <p style={{ color: "red" }}>{apiErrorMessage}</p>
              </>
            )}
            <Button
              onClick={handleSubmit}
              style={{ background: "#0E4D92" }}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              PROCEED
            </Button>
            <p className="back-to-login" onClick={handleClose}>
              Back to Login
            </p>
          </DialogContentText>
        </DialogContent>
      )}
      <button onClick={handleClose} className="dialogue_box-close">
        {/* <AiOutlineClose></AiOutlineClose> */}
        <img src="/icons/close.svg" alt="icon" width={45}/>
      </button>
    </Dialog>
  );
}
