import { useEffect, useState } from "react";
import { AiOutlineFolderView, AiTwotoneEdit } from "react-icons/ai";
import { BiConversation, BiSearch } from "react-icons/bi";
import { FaDownload } from "react-icons/fa";
import {
  MdOutlineAssignmentTurnedIn,
  MdOutlineSpaceDashboard,
} from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth.hook";
import { addToaster } from "../../../redux/appToaster/appToaster.actions";

import AlertDialog from "../AlertDialog/AlertDialog.component";

const FormatActionComponent = ({
  cell,
  row,
  rowIndex,
  formatExtraData,
  setSelectedRowIndex,
  actionMenuRef,
  location,
  deleteRecord,
  dispatchActions,
}) => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { getUserAuth } = useAuth();
  const [userAuth, setUserAuth] = useState(null);
  // const [toastList, setToastList] = useState([]);
  const [confirmDialog, setConfirmDiaogue] = useState({
    openAlert: false,
    alertMessage: "",
  });
  const [assignBPDialog, setAssignBPDiaogue] = useState({
    openDialogue: false,
    dialogueMessage: "",
  });
  const [confirmAction, setConfirmAction] = useState(false);

  const handleRoute = () => {
    switch (location?.pathname) {
      case "/":
        return navigateTo(`/manageOrganization/view/${row?._id}`);
      case "/manageHiringManager":
        return navigateTo(`./edit/${row?._id}`);
      case "/manageCandidate":
        return navigateTo(`./edit/${row?._id}`);
      case "/manageBusinessPsychologist":
        return navigateTo(`./edit/${row?._id}`);
      case "/manageJobRole":
        return navigateTo(`./edit/${row?.value}`);
      default:
        return navigateTo(`./edit/${row?.value || row?._id}`);
    }
  };

  useEffect(() => {
    const authValues = getUserAuth();
    setUserAuth(authValues);
  }, [getUserAuth]);

  useEffect(() => {
    if (confirmAction) {
      deleteRecord(row.value || row._id)
        .then((res) => {
          dispatch(
            addToaster({
              id: 0,
              title: "Job Role Deleted",
              description: "Job role data has been deleted successfully.",
              icon: "success",
            })
          );
          setConfirmDiaogue({ openAlert: false, alertMessage: "" });
        })
        .catch((err) => {
          setConfirmDiaogue({ openAlert: false, alertMessage: "" });
          dispatch(
            addToaster({
              id: 0,
              title: "Delete Error",
              description: err?.response?.data?.message,
              icon: "error",
            })
          );
        });
      setConfirmAction(false);
    }
  }, [confirmAction]);

  const deleteJobRole = () => {
    switch (location?.pathname) {
      case "/":
        return void 0;
      case "/manageHiringManager":
        // return navigateTo(`./edit/${row?._id}`);
        return void 0;
      case "/manageCandidate":
        return void 0;
      case "/manageJobRole":
        return setConfirmDiaogue({
          openAlert: true,
          alertMessage: `Are you sure to delete this Job Role?`,
        });
      default:
        break;
    }
  };

  const handleCandidateIcon = (icon) => {
    switch (icon) {
      case "view":
        return <AiOutlineFolderView />;
      case "edit":
        return <AiTwotoneEdit />;
      case "assign":
        return <MdOutlineAssignmentTurnedIn />;
      default:
        return <BiConversation />;
    }
  };

  const renderSwitchMenu = (row) => {
    const split = location.pathname.split("/")[1];
  };

  useEffect(() => {
    return () => {
      setConfirmAction(false);
    };
  }, []);

  return (
    <span
      key={rowIndex}
      className="table_action"
      style={{ textAlign: "center" }}
    >
      <div className="table_action-icon">
        {/* <img
          alt="table-action"
          onClick={(e) => {
            setSelectedRowIndex(row._id || row.value);
            e.stopPropagation();
          }}
          className="action-icon"
          src={actionIcon}
        ></img> */}
      </div>
      {formatExtraData === (row._id || row.value) ? (
        <div className="table_action-popup" ref={actionMenuRef}>
          {
            <div className="table_action-popup_wrapper">
              {renderSwitchMenu(row)}
            </div>
          }
        </div>
      ) : (
        ""
      )}
      <AlertDialog
        openAlert={confirmDialog?.openAlert}
        alertMessage={confirmDialog.alertMessage}
        setConfirmDiaogue={setConfirmDiaogue}
        setConfirmAction={setConfirmAction}
      />
    </span>
  );
};

export default FormatActionComponent;
