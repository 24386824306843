import { axiosInstance } from "../../../constants/axiosInstance";
import { endpoints } from "../../../constants/url.endpoint";
import { manageUsersActions } from "./manageUsers.reducer";

const {
  manageUsersSuccess,
  addUserSuccess,
  viewUserSuccess,
  resetManageUsersData,
  resetUserDetail,
  viewUsersDetailsSuccess,
} = manageUsersActions;

export const fetchUserListing =
  (axiosInstance, apiData) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.userListing,
        method: "POST",
        params: apiData,
      })
        .then((res) => {
          dispatch(manageUsersSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          dispatch(resetUserDetail());
          reject(err);
        });
    });
  };

export const addUser = (axiosInstance, userDetails, params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: endpoints.addUser,
      method: "POST",
      data: userDetails,
      params: params,
    })
      .then((response) => {
        dispatch(addUserSuccess(response?.data?.data));
        resolve(response?.data);
      })
      .catch((error) => {
        dispatch(resetUserDetail());
        reject(error);
      });
  });
}

export const viewUser = (axiosInstance, email, params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: endpoints.viewUser,
      method: "POST",
      params: params,
      data: {
        email: email
      }
    })
      .then((response) => {
        dispatch(viewUserSuccess(response?.data?.data));
        resolve(response?.data?.data);
      })
      .catch((error) => {
        dispatch(resetUserDetail());
        reject(error);
      });
  });
}