export const routeItems = [
  {
    label: "Login",
    route: "/login",
    component: "LoginPage",
    roleLevel: [],
    protected: false,
  },
  {
    label: "ForgetPassword",
    route: "/forget-password",
    component: "ForgetPassword",
    roleLevel: [],
    protected: false,
  },
  {
    label: "RedirectApp",
    route: "/redirect-app/:token",
    component: "RedirectApp",
    roleLevel: [],
    protected: false,
  },
  {
    label: "Dashboard",
    route: "/",
    component: "HomePage",
    roleLevel: [],
    protected: true,
  },
  {
    label: "ResetPassword",
    route: "/reset-password/:id",
    component: "ResetPassword",
    roleLevel: [],
    protected: false,
  },
  {
    label: "User Management",
    route: "/userManagement",
    component: "UserManagement",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Add User",
    route: "/addUser",
    component: "AddUser",
    roleLevel: [],
    protected: true,
  },
  {
    label: "View User",
    route: "/viewUser",
    component: "ViewUser",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Edit User",
    route: "/editUser",
    component: "EditUser",
    roleLevel: [],
    protected: true,
  },
];
