import { useState } from 'react';
import Back from '../../../../shared/components/backButton/backButton';
import Button from '../../../../shared/components/button/button';
import Heading from '../../../../shared/components/heading/heading';
import ImageUpload from '../../../../shared/components/imageUpload/imageUpload';
import Input from '../../../../shared/components/inputFields/input';
import { USER_MANAGEMENT } from '../userManagementConstants';
import style from './editUser.module.scss';
import buttonStyle from '../../../../shared/components/button/button.module.scss';
import { AppType } from '../../../../shared/globalConstants/constants';

const EditUser = () => {

    const inputFields = [
        {
            type: "text",
            name: "firstName",
            placeholder: 'First Name',
            label: 'First Name',
            width: '50%'
        },
        {
            type: "text",
            name: "lastName",
            placeholder: 'Last Name',
            label: 'Last Name',
            width: '50%'
        },
        {
            type: "text",
            name: "email",
            placeholder: 'Email ID',
            label: 'Email ID',
            width: '50%'
        },
        {
            type: "dropdown",
            name: "userType",
            placeholder: 'Select User Type',
            label: 'User Type',
            options: AppType,
            width: '50%'
        }
    ];
    const [formData, setFormData] = useState({});

    const handleInputChange = (fieldName, fieldValue) => {
        setFormData({ ...formData, [fieldName]: fieldValue });
    };

    return (
        <div className={style.container}>
            <div className={style.wrapper}>
                <div className={style.back}>
                    <Back title={USER_MANAGEMENT.EDIT_USER.BACK.TITLE} description={USER_MANAGEMENT.EDIT_USER.BACK.DESCRIPTION} />
                </div>
                <div className={style.heading}>
                    <div className={style.app_user}>
                        <img src='/icons/info_blue.svg' alt='info' width={30} />
                        <span className={style.app}>Aur La Vie</span>
                        <span className={style.user}>Normal User</span>
                    </div>
                </div>
                <div className={style.form_container}>
                    <div className={style.upload}>
                        <ImageUpload title={USER_MANAGEMENT.EDIT_USER.UPLOAD.TITLE} />
                    </div>
                    <form>
                        <Heading title={USER_MANAGEMENT.EDIT_USER.SUB_HEADING.TITLE} description={USER_MANAGEMENT.EDIT_USER.SUB_HEADING.DESCRIPTION} fontSize={'1.4rem'} />
                        <hr className={style.divider} />
                        <div className={style.form_container}>
                            {inputFields.map((inputField, index) => (
                                <div key={index} className={style.input_field} style={{ width: inputField.width }}>
                                    <Input
                                        type={inputField.type}
                                        label={inputField.label}
                                        value={formData[inputField.name]}
                                        placeholder={inputField.placeholder}
                                        options={inputField.options}
                                        onChange={(value) => handleInputChange(inputField.fieldName, value)}
                                    />
                                </div>
                            ))}
                            <div className={style.action_button}>
                                <Button text={USER_MANAGEMENT.EDIT_USER.BUTTONS.TITLE} buttonType={`${buttonStyle.button_primary}`} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditUser;