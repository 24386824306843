import React, { useEffect, useRef, useState } from "react";
import { IoMdNotifications } from "react-icons/io";
import { MdHelp } from "react-icons/md";
import "./header.component.scss";
import { useDispatch } from "react-redux";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import io from "socket.io-client";
import useHandleLogout from "../../../hooks/useHandleLogout.hook";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import NotificationMenuComponent from "../Notification/Notification.component";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import { fetchMyAccountUserDetail } from "../../../redux/Common/myAccount/myAccount.actions";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useAuth from "../../../hooks/useAuth.hook";
import { fetchNotificationCount } from "../../../redux/Common/manageNotification/manageNotification.actions";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// const socket = io.connect(process.env.REACT_APP_SOCKET_URL);

const HeaderComponent = () => {
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInstance();
  const [logoutMenu, setLogoutMenu] = useState(false);
  const { handleLogout } = useHandleLogout();
  const logoutMenuRef = useRef();
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationToggle, setNotificationToggle] = useState(false);
  const [userInitial, setUserInitial] = useState("");
  const [userDetail, setUserDetail] = useState(null);
  const notificationRef = useRef();

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   socket.on("connect", () => {
  //     socket.on(`notify_${localStorage.getItem("userId")}`, (res) => {
  //       fetchUnreadCount();
  //     });
  //   });
  // }, []);

  // const fetchUnreadCount = () => {
  //   dispatch(fetchNotificationCount(axiosInstance, {})).then((res) => {
  //     setNotificationCount(res?.data?.data?.totalCount);
  //   });
  // };

  // useEffect(() => {
  //   fetchUnreadCount();
  // }, []);

  // useEffect(() => {
  //   dispatch(fetchMyAccountUserDetail(axiosInstance)).then((res) => {
  //     setUserDetail(res?.user);
  //   });
  // }, [axiosInstance, dispatch]);

  useEffect(() => {
    userDetail &&
      setUserInitial(`${userDetail.firstname[0]}${userDetail.lastname[0]}`);
  }, [userDetail]);

  useEffect(() => {
    //for listening to on-click event for table actions
    function handler(event) {
      if (!logoutMenuRef?.current?.contains(event?.target)) {
        setLogoutMenu(false);
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  useEffect(() => {
    //for listening to on-click event for notification menu
    function handler(event) {
      if (!notificationRef.current?.contains(event.target)) {
        setNotificationToggle(false);
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  return (
    <div className="header-container">
      <div className="header-wrapper">
        <div className="header-logo">
          {/* <img src="/logo-one.png" className="header-logo-image" alt="logo" /> */}
        </div>
        <div className="header-icons">
          {/* <div className="header-icons-search"> */}
          {/* <AiOutlineSearch className='header-icons-search-icon'/> */}
          {/* <p>Search</p> */}
          {/* </div> */}
          {/* <div className="header-icons-help">
            <MdHelp />
            <p>Help</p>
          </div>
          <div
            className="header-icons-notifications"
            onClick={() => setNotificationToggle(true)}
            ref={notificationRef}
          >
            <IoMdNotifications />
            {notificationCount > 0 && (
              <span className="header-icons-notifications-alert">
                <p>{notificationCount}</p>
              </span>
            )}
            {notificationToggle ? (
              <NotificationMenuComponent
                setNotificationCount={setNotificationCount}
                setNotificationToggle={setNotificationToggle}
              />
            ) : (
              <></>
            )}
          </div> */}
          <div>
            <img src="/icons/language_blue.svg" alt="language " className="language_icon" width={30}/>
            <img src="/icons/user_blue.svg" alt="user" className="user_icon" width={40}/>
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              //   variant="contained"
              disableElevation
              onClick={handleClick}
              endIcon={<ArrowDropDownIcon className="arrow-image" />}
            >
              <div className="welcome-user-container">
                <p className="welcome-text">Welcome</p>
                <p className="user-name">User</p>
              </div>
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} disableRipple>
                <AccountCircleRoundedIcon />
                <p
                  style={{
                    fontSize: "14px",
                    color: "#0E4D92",
                    fontWeight: "500",
                  }}
                >
                  Profile
                </p>
              </MenuItem>
              <MenuItem onClick={handleLogout} disableRipple>
                <PowerSettingsNewOutlinedIcon />
                <p
                  style={{
                    fontSize: "14px",
                    color: "#0E4D92",
                    fontWeight: "500",
                  }}
                >
                  Logout
                </p>
              </MenuItem>
              {/* <Divider sx={{ my: 0.5 }} />
              <MenuItem onClick={handleClose} disableRipple>
                <ArchiveIcon />
                Archive
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                <MoreHorizIcon />
                More
              </MenuItem> */}
            </StyledMenu>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;
