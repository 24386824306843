import './heading.scss';

const Heading = (props) => {

    const { icon, title, fontSize, textAlign, description } = props;

    return <div className="heading_container">
        <div className="heading_wrapper">
            {icon !== undefined ? <div className="icon">
                <img src={icon} alt={title} loading="lazy"/>
            </div> : ''}
            <div className="heading" style={{textAlign: textAlign}}>
                <h1 className="title" style={{fontSize: fontSize}}>{title}</h1>
                <p className="description">{description}</p>
            </div>
        </div>
    </div>
}

export default Heading;