import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Toaster from "../../components/Global/Toaster/Toaster.component";
import ButtonEvent from "../../components/Global/inputs/button.component";
import InputEvent from "../../components/Global/inputs/input.component";
import { resetPasswordAction } from "../../redux/users/user.actions";
import { passwordToolTip } from "./resetPassword.constant";
import "./resetPassword.styles.scss";
import { validate } from "./resetPasswordValidation";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const ForgetPasswordPage = () => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState(null);
  const [toastList, setToastList] = useState([]);

  useEffect(() => {
    return () => {
      clearInterval();
    };
  }, []);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      handleResetPassword();
    }
  }, [formErrors, isSubmit]);

  const onVisibleClick = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleResetPassword = () => {
    const routeSplit = location?.pathname.split("/");
    const urlToken = routeSplit[routeSplit.length - 1];
    dispatch(
      resetPasswordAction({
        password,
        passwordConfirm: confirmPassword,
        urlToken,
      })
    )
      .then((res) => {
        setToastList([
          {
            id: 0,
            title: "Reset Password Success",
            description: "Password has been updated succcessfully.",
            icon: "success",
          },
        ]);
        setTimeout(() => {
          navigateTo("/login");
        }, 1500);
      })
      .catch((err) => {
        setToastList([
          {
            id: 0,
            title: "Reset Password Error",
            description: err?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setApiErrorMessage(null);
    setFormErrors(validate({ password, confirmPassword }));
    setIsSubmit(true);
  };

  return (
    <div className="reset-container">
      <div className="reset">
        <DialogContent>
          <DialogTitle
            sx={{
              fontWeight: "900",
            }}
            className="dialogue-title"
            id="alert-dialog-title"
          >
            Reset Password
          </DialogTitle>
          <DialogContentText id="alert-dialog-description">
            <p className="dialogue-description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
              efficitur arcu vel sapien posuere accumsan.
            </p>
            <form onSubmit={handleSubmit}>
              <InputEvent
                text="New Password"
                maxLength={16}
                type={passwordVisible ? "text" : "password"}
                value={password}
                setValue={setPassword}
                icon={
                  passwordVisible
                    ? "/icons/visible-eye.png"
                    : "/icons/not-visible-eye.png"
                }
                iconFunc={onVisibleClick}
              />

              {formErrors.password && (
                <>
                  <p className="input-error">{formErrors?.password}</p>
                </>
              )}
              <InputEvent
                text="Confirm New Password"
                id="reset_confirm_pass"
                type={"text"}
                value={confirmPassword}
                setValue={setConfirmPassword}
              />

              {formErrors.confirmPassword && (
                <>
                  <p className="input-error">{formErrors?.confirmPassword}</p>
                </>
              )}

              {apiErrorMessage && (
                <>
                  <p className="input-error">{apiErrorMessage}</p>
                </>
              )}
              <Button
                onClick={handleSubmit}
                style={{ background: "#0E4D92" }}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                RESET PASSWORD
              </Button>
              <p className="back-to-login">Back to Login</p>
            </form>
          </DialogContentText>
        </DialogContent>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </div>
  );
};

export default ForgetPasswordPage;
